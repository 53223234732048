import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const EricNealStoryPage = () => (

    <Layout pageClass="story-page">
    <SEO title="Eric Neal - Ace Business Solutions - Story" />
    <main className="main">
        <div className="typography inner">
            <h1>Eric Neal - Ace Business Solutions</h1>          
            <p>All successful businesses are built on good ideas and good management, but the most successful ones are those that also consider the views of others. One bad habit can easily restrict the growth of a business and can even cause the failure of the business. Sometimes we can be blind to the problem or problems, and that is why we need someone to point things out to us and suggest improvements.</p>

           <p>Using Dave Wylie of Experience on Tap as that extra pair of eyes is invaluable. Bouncing ideas off Dave and drawing on his vast business experience can result in huge benefits. The thing I really like about working with Dave, is that Experience on Tap doesn’t lock you into a big contract. As their name infers, you can draw on their services if and when you want, so my suggestion is to have an initial meeting with Dave who can assess how your business is going. He will give you a full report with suggestions for changes and plans to help grow your business, and then just touch base with Dave on a regular basis to ensure your business continues in the right direction, short-term, medium-term and long-term.</p>

            <p>This is how my relationship with Dave works, and his advice has contributed greatly to the stability and success of my business over the past fifteen years. Thank you, Dave!</p>
        </div>
    </main>
  </Layout>

)


export default EricNealStoryPage